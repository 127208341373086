a{
  font-size: 1rem;
}
.lidivion {
  display: inline-block;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  height: 1.5rem;
  vertical-align: baseline;
  font-size: 1rem;
  line-height: 0rem;
  margin: 0 10px;
  margin-bottom: -0.4rem;
  
}
.filter {
  display: flex;
  width: 100%;
  padding-right: 1rem;
}

.filter .sports {
  padding-bottom: 0.8rem;
  margin-bottom: 0.8rem;
  position: relative;
}
.filter .sports::after {
  content: "";
  border-bottom: 1px solid #e3e3e3;
  position: absolute;
  bottom: -0.1rem;
  left: 0.3rem;
  right: 0.5rem;
}

.filter .fltCnt {
  flex-grow: 1;
  margin-right: 1rem;
  position: relative;
}

.filter .fltCnt::after {
  content: "";
  border-left: 1px solid #e3e3e3;
  position: absolute;
  top: 0.3rem;
  bottom: 0.5rem;
  width: 2px;
  right: -0.6rem;
}
.filter .fltCnt .brSrWrp {
  padding: 0 0.5rem 1rem;
}

.filter .fltCntPkg {
  width: 200px;
}

.fltCntPkg .button {
  padding: 0.9rem 1.66rem;
  color: rgba(101, 101, 101, 1);
  margin: 0.25rem 0.66rem 0.5rem;
  font-weight: 600;
  box-shadow: 0 0 0.41rem rgba(0, 0, 0, 0.1);
  font-family: "Arial-Bold";
  display: block;
  font-size: 1rem;
  width: 100%;
}
.sports .button:first-child,
.sports-disciplines .button:first-child {
  height: 6.5rem;
  float: left;
}
.sports-disciplines .button:first-child {
  height: 14.1rem;
  float: left;
}
.sports .button,
.sports-disciplines .button,
.dateWpr .button {
  padding: 0.9rem 1.3rem;
  color: rgba(101, 101, 101, 1);
  margin: 0.25rem 0.3rem 0.5rem;
  font-weight: 600;
  box-shadow: 0 0 0.41rem rgba(0, 0, 0, 0.1);
  font-family: "Arial-Bold";
  display: inline-block;
  font-size: 1rem;
  word-wrap: break-word;
}

.dateWpr .button {
  padding: 0.9rem 0.9rem;
  margin: 0.25rem 0rem 0.5rem;
}

.dateWpr .monthTxt {
  font-size: 1.08rem;
  color: #5a5a5a;
  text-align: center;
  padding-bottom: 0.2rem;
  margin: 0.9rem 0 0 0;
  font-family: "Arail-Bold";
}
.listWrapper {
  display: flex;
  padding-top: 1rem;
}
.listWrapper .listContent {
  margin: 1rem 0;
  flex-grow: 1;
}

.listWrapper .listContent .listWpr .slPkgUk .slpkgHdr {
  padding: 0.8rem 0.4rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: space-around;
}

.listWrapper .listContent .listWpr:first-child {
  padding-top: 0rem;
}
.listWrapper .dateWpr {
  width: 50px;
  text-align: center;
}

.listCnt .slPkgUk {
  margin: 0;
  padding: 0 0 0 0;
}
.listCnt .slPkgUk .slpkgHdr {
  margin: 0;
  padding: 0;
}

.listCnt .slPkgUk .slpkgTtl {
  display: flex;
  padding-top: 0.8rem;
}
.listCnt .slPkgUk .slpkgTtl .noOfGuests,
.listCnt .slPkgUk .slpkgTtl .packageCost,
.listCnt .slPkgUk .slpkgTtl .costTxt,
.listCnt .slPkgUk .slpkgTtl .totalcost {
  font-size: 1.25rem;
  color: #0183bd;
  line-height: 1.5rem;
  font-family: "Arial-Bold";
}
.listCnt .slPkgUk .slpkgTtl .totalcost,
.listCnt .slPkgUk .slpkgItm .videdetails {
  align-items: flex-end;
  text-align: right;
}
.listCnt .slPkgUk .slpkgTtl .totalcost {
  padding: 0 0.7rem;
  width: 10rem;
}

.listCnt .slPkgUk .slpkgTtl .costTxt {
  margin-left: auto;
  font-size: 1rem;
  color: #5a5a5a;
  font-family: "Arial-Bold";
  padding: 0 2rem;
  text-align: right;
}
.listCnt .slPkgUk .slpkgHdr,
.listCnt .slPkgUk .slpkgItm {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0.8rem 0.4rem;
  border-bottom: 1px solid #e3e3e3;
}

.listCnt .slPkgUk .slpkgItm:last-child {
  border-bottom: none;
}

.listCnt .slPkgUk .slpkgHdr .sr,
.listCnt .slPkgUk .slpkgItm .sr {
  width: 190px;
}

.listCnt .slPkgUk .slpkgHdr .srdes,
.listCnt .slPkgUk .slpkgItm .srdes {
  width: 340px;
}

.listCnt .slPkgUk .slpkgHdr .action,
.listCnt .slPkgUk .slpkgItm .action {
  max-width: 100px;
  text-align: right;
}
.listCnt .slPkgUk .slpkgHdr .message,
.listCnt .slPkgUk .slpkgItm .message,
.listCnt .slPkgUk .slpkgHdr .url,
.listCnt .slPkgUk .slpkgItm .url {
  width: 300px;
  text-align: center;
}

.listCnt .slPkgUk .slpkgHdr .url {
  width: 240px;
}

.listCnt .slPkgUk .slpkgHdr .srdes {
  text-align: center;
}
.listCnt .slPkgUk .slpkgHdr .date,
.listCnt .slPkgUk .slpkgItm .date {
  width: 140px;
  text-align: center;
}

/* text formatting heading */
.listCnt .slPkgUk .slpkgHdr .sr,
.listCnt .slPkgUk .slpkgHdr .srdes,
.listCnt .slPkgUk .slpkgHdr .status,
.listCnt .slPkgUk .slpkgHdr .message,
.listCnt .slPkgUk .slpkgHdr .url,
.listCnt .slPkgUk .slpkgHdr .date {
  font-size: 1.18rem;
  color: #5a5a5a;
  line-height: 1.41rem;
  font-weight: bold;
}
.listCnt .slPkgUk .slpkgHdr .srdes,
.listCnt .slPkgUk .slpkgHdr .status {
  text-align: center;
  align-self: flex-start;
  justify-content: center;
}
.listCnt .slPkgUk .slpkgHdr .status, 
.listCnt .slPkgUk .slpkgItm .status {
  width: 100px;
}
/* text formatting */
.listCnt .slPkgUk .slpkgItm .sr,
.listCnt .slPkgUk .slpkgItm .srdes,
.listCnt .slPkgUk .slpkgItm .date,
.listCnt .slPkgUk .slpkgItm .status,
.listCnt .slPkgUk .slpkgItm .message,
.listCnt .slPkgUk .slpkgItm .url,
.listCnt .slPkgUk .slpkgItm .eventhiglights {
  font-size: 1.1rem;
  font-family: "Arial";
  color: #5a5a5a;
  line-height: 1.66rem;
  word-break: break-word;
}
.listCnt .slPkgUk .slpkgItm .srdes,
.listCnt .slPkgUk .slpkgItm .date,
.listCnt .slPkgUk .slpkgItm .status,
.listCnt .slPkgUk .slpkgItm .viewdetails,
.listCnt .slPkgUk .slpkgItm .eventhiglights {
  align-items: flex-start;
  justify-content: center;
  text-align: center;
}
.listCnt .slPkgUk .slpkgItm .srdes {
  text-align: left;
}

.listCnt .slPkgUk .slpkgItm .eventhiglights {
  flex-grow: 1;
  color: #9b7b50;
  line-height: 1.66rem;
  font-size: 1rem;
  font-family: "Arial-Bold";
  width: 300px;
  text-align: left;
  padding-left: 1rem;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
}

.eventhinlightscol {
  padding: 0.2rem 0.3rem 0;
}

.listCnt .slPkgUk .slpkgItm .eventhiglights .awardIcon {
  width: 16px;
}
.listCnt .slPkgUk .slpkgHdr .srAddToCart {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  justify-self: flex-end;
  margin-left: auto;
  width: 286px;
}

.listCnt .slPkgUk .slpkgHdr .srAddToCart .srCurrancy {
  color: #0183bd;
  font-size: 1.16rem;
  line-height: 1.41rem;
  font-family: "Arial-Bold";
  margin-right: 1rem;
}

.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnWpr {
  display: flex;
  flex-direction: row;
  margin-left: auto;
}

.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnCnt {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin-right: 0.46rem;
  box-shadow: 0 0 0.41rem rgba(0, 0, 0, 0.15);
}

.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnCnt .button {
  padding: 0.5rem;
  height: 32px;
  width: 32px;
}
.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnCnt .saprator {
  width: 1px;
  border-left: 1px solid #e3e3e3;
  margin: 5px 0;
}
.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnCnt .inputText {
  width: 36px;
  text-align: center;
  color: #5a5a5a;
  font-size: 1.33rem;
  padding: 0.5rem;
}

.listCnt .slPkgUk .slpkgHdr .srAddToCart .addToCartBtnWpr > .button-primary {
  padding: 0.5rem;
  height: 32px;
  width: 80px;
  text-transform: uppercase;
}

.sptFtBtnWpr {
  width: 11%;
}

.listPage .sports div.button-link,
.listPage .sports-disciplines div.button-link {
  width: 10.4%;
  display: inline-block;
  padding: 0.3rem;
  height: 3.3rem;
}
.listPage .sports .button-link,
.listPage .sports-disciplines .button-link {
  width: 100%;
  height: 2.8rem;
  display: block;
  margin: 0;
  padding: 0;
  overflow: hidden;
}
.addToCartBtnWpr {
  text-align: right;
}


select {
  padding: 1rem;
  border-radius: 0px;
  -webkit-appearance: none;
  font-size: 1.1rem;
  background-image: url("../image/dropdown.png");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 1.2rem;
  background-color: #ffffff;
  margin: 0.8em 0 0;
}


.site-settings {
  position: relative;
}

.settings {
  padding: 0 4rem;
  max-width: 550px;
  margin: 0 auto;
}

.settings label {
  font-size: 22px;
}
.settings label.labelTitle {
  font-size: 12px;
}

.pt-4 {
  padding-top: 1rem;
}

.email .url,
.email .status,
.email .date {
  display: none;
} 

.site-settings label {
  display: flex;
}

.site-settings input[type="checkbox"]  {
  width: 1px !important;
  height: 1px !important;
  opacity: 0 !important;
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
}




.site-settings .checkmark {
  min-width: 25px;
  width: 25px;
  height: 25px;
  background-color: #D2E6FF;
  display: inline-block;
  margin-right: 10px;
  position: relative;
  border-radius: 3px;
}

.site-settings input:checked + .checkmark::before {
  display: block !important;
}

.site-settings .checkmark::before {
    content: "";
    width: 15px;
    height: 14px;
    background: url("../image/checkmark.svg") no-repeat center/cover;
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

