html,
body {
  -webkit-tap-highlight-color: transparent;
  font-size: 12px;
  height: 100%;
}

body > div#root {
  height: 100%;
}
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0;
}
.App {
  /*text-align: center;*/
}
.contentWrapper {
  padding-top: 5rem;
  margin: 0;
  height: 100%;
}

.contentWrapper > .mapcontainer {
  position: absolute;
  bottom: 0px;
  top: 5rem;
  left: 0px;
  right: 0px;
  overflow: hidden;
}
.contentWrapper > .container {
  bottom: 0px;
  top: 10rem;
  left: 0px;
  right: 0px;
  overflow: hidden;
  padding-top: 60px;
}
.container.flexbox {
  display: flex;
}
.staticBdrContainer {
  /* width: 1180px; */
  width: 100%;
  margin: 0.833rem auto;
  padding: 1rem;
  display: flex;
}

.App-logo {
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.slideNavigation {
}

.slideNavigation ul,
.slideNavigation li {
  display: "block";
}
.textualContent {
    flex: 0.5;
    padding: 20px;
    overflow: hidden;
  }
  
  .InputText {
    border: 1px solid #c6c6c6;
    box-sizing: border-box;
    padding: 1rem;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: #656565;
    display: block;
    width: 100%;
    font-family: "Arial";
    margin: 0.8rem 0 0;
    border-radius: 0;
    vertical-align: middle;
    box-shadow: none;
  }
  .successMessage,
  .errorMessage {
    font-size: 0.9rem;
    padding: 0.5rem;
    color: green;
  }
  .errorMessage {
    color: red;
  }
  .fontBold {
    font-family: "Arial";
  }
  
  .clearAll {
    position: absolute;
    bottom: 1rem;
    z-index: 100;
    padding: 1rem 2rem 1rem 1.2rem
  }
  @media (max-width: 1160px) {
    .clearAll {
      position: absolute;
      bottom: 5rem;
      z-index: 100;
      padding: 1rem 2rem 1rem 1.2rem
    }
  
  }
  .homeclearall {
    width: 10px;
    margin-right: 10px;
  }
  
  .wprdiv .clearAll {
    padding: 1rem 2rem;
  }
  
  .loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.4);
    z-index: 999999;
    display: none;
  }
  
  .loader-gif {
    position: absolute;
    margin-top: -8px;
    margin-left: -8px;
    top: 50%;
    left: 50%;
  }