.fixed-top {
  display: block;
  width: 100%;
  /* height: 5rem; */
  top: 0;
  right: 0;
  left: 0;
  z-index: 1035;
  position: fixed;
  background: rgb(255, 255, 255);
}

.navbar {
  display: flex;
  width: 100%;
  padding: 1.6rem 0;
  flex-flow: row nowrap;
  justify-content: space-between;
  border-bottom: 1px solid rgba(128,128,128,0.2);
}



.logo-wrapper {
  width: 30%;
}

.logo-wrapper img {
  width: 60px !important;
}

.menu-button {
  background: transparent;
  border: none;
  display: inline-block;
  width: 32px;
  text-align: left;
  padding-left: 0;
}

.basket-button {
  background: transparent;
  border: none;
  display: inline-block;
  text-align: left;
  padding: 0 10px;
  position: relative;
  font-size: 1em !important;
  font-weight: bold !important;
}
.updateToCart {
  display: inline-block;
  border-radius: 5px;
  background: red;
  width: 10px;
  height: 10px;
  position: absolute;
  top: -3px;
  right: 5px;
}
.logo-wrapper {
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: left;
  padding: 0 20px;
}

.top-nav {
  display: flex;
  vertical-align: middle;
  width: 100px;
  text-align: right;
  padding: 0 10px 0 0;
  flex-basis: 50%;
  justify-content: space-between;
  align-items: center;
}
.top-nav li {
  /* display: table-cell; */
  list-style-type: none;
  color: white;
}

.top-nav div a {
  color: #00005f;
  text-decoration: none;
  font-size: 1em !important;
    font-weight: bold !important;
    text-transform: capitalize;
}

.leftNavigation.active {
  display: block;
  overflow: auto;
}

.leftNavigation {
  background: rgba(0, 0, 0, 0.3);
  z-index: 400;
  top: 5rem;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  position: fixed;
}

.leftNavbar {
  width: 30rem;
  background: #fff;
  padding: 0;
  margin: 0;
  float: left;
}

.leftNavbar {
  padding: 0;
  margin: 0;
}

.leftNavbar li {
  padding: 0 1.6rem;
  margin: 0;
}

.leftNavbar li::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  display: block;
}

.leftNavbar li.noborder::after {
  width: 0;
  height: 0px;
  background: transparent;
  display: none;
}

.leftNavbar li .leftNavbarLink {
  font-size: 1.25rem;
  padding: 2rem 1.6rem;
  display: block;
  color: rgba(126, 126, 126, 1);
  text-decoration: none;
  font-weight: 500;
}

.leftNavbar li.language {
  font-family: "Tokyo2020";
  color: rgba(126, 126, 126, 1);
}

.leftNavbar li.language p {
  font-family: "Tokyo2020";
  color: rgba(126, 126, 126, 1);
  margin-top: 0;
  font-size: 1.25rem;
}

.leftNavbar li.language label {
  font-family: "Tokyo2020";
  color: rgba(126, 126, 126, 1);
  padding: 0 1rem 0 0;
  font-size: 1rem;
}
.leftNavbar li .leftNavbarLink.active {
  border-bottom: rgba(8, 130, 189, 1) 3px solid;
  display: inline-block;
  color: rgba(16, 50, 100, 1);
}

.leftNavbar li.logout {
  background: rgba(208, 208, 208, 1);
  text-align: center;
  margin-top: 8rem;
}
.leftNavbar li .logoutlink {
  text-align: center;
  padding: 1.6rem 0;
  color: #fff;
  background: transparent;
  width: 100%;
  margin: 0;
  box-shadow: none;
}
.leftNavigation .close-button {
  background: rgba(0, 75, 153, 1);
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: none;
  float: left;
  padding: 0.8rem;
  margin: 0;
}

/* right navigation */

.rightNavigation.active {
  display: block;
}

.rightNavigation {
  background: rgba(0, 0, 0, 0.3);
  z-index: 400;
  top: 5rem;
  bottom: 0;
  right: 0;
  left: 0;
  display: none;
  position: fixed;
  flex-direction: column;
  z-index: 1000;
  overflow: hidden;
}

.rightCart {
  width: 70rem;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  overflow: auto;
  right: 0;
}

.rightCart.shareQuote {
  width: 45rem;
}

.rightCart .flex1,
.rightCart .flex2,
.rightCart .flex3 {
  background: #fff;
  margin-bottom: 2rem;
}
.rightCart .cartItems {
  min-height: 90%;
  display: flex;
  flex-direction: column;
}
.rightCart .flex1 {
  flex-grow: 1;
  padding: 1.7rem 1.8rem 1.8rem 2.8rem;
}
.rightCart .listWrapper .listContent {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.rightCart .cartTotalCost .costWpr {
  padding-top: 2rem;
}
.rightCart .cartTotalCost,
.rightCart .cartTotalCost .costWpr {
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: stretch;
}
.rightCart .cartTotalCost .costWpr > div {
  flex: 1;
}

.rightCart .cartTotalCost .costWpr > div,
.rightCart .cartTotalCost .costWpr > div p {
  color: #0f3263;
  font-size: 1.5rem;
  font-family: "Arial";
}
.rightCart .cartTotalCost .costWpr .cartTotalCost {
  margin-left: auto;
  justify-content: flex-end;
  color: #0183bd;
  padding-right: 0.8rem;
}

.rightCart .listWrapper .listContent .listWpr {
  margin-top: auto;
}

.rightCart .flex1 .quotesTitle {
  margin: 0;
  padding: 0 0 1.75rem;
  font-size: 1.5rem;
  color: #0f3263;
  font-family: "Arial";
}
.rightCart .flex1 .shareQuotesTitle {
  margin: 0;
  padding: 0 0 1.75rem;
  font-size: 1.5rem;
  color: #0f3263;
  font-family: "Arial";
  display: flex;
  align-items: center;
}
.rightCart .flex1 .shareQuotesDesc {
  margin: 0;
  padding: 0 0 1.75rem;
  font-size: 1.16rem;
  line-height: 1.42rem;
  color: #5a5a5a;
  font-family: "Arial";
  display: flex;
  align-items: center;
}
.rightCart .flex1 .labelTitle {
  font-family: "Arial";
  color: #656565;
  display: block;
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.rightCart .flex1 .filedGroup {
  margin-top: 1.25rem;
}
.shareQuotesTextField {
  border: 1px solid #c6c6c6;
  box-sizing: border-box;
  padding: 1rem;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #656565;
  display: block;
  width: 100%;
  font-family: "Arial";
  margin: 0.8rem 0 0;
}

.filedGroupCheckboxText {
  display: flex;
  margin-top: 3.25rem;
  align-items: flex-start;
}
.filedGroupCheckbox {
  display: flex;
  margin-top: 0;
  align-items: center;
}
.filedGroupCheckbox.atop {
  margin-top: 3.25rem;
}

.filedGroupCheckbox .tncDesc {
  margin: 0;
  padding: 0 1.75rem;
  font-size: 1.16rem;
  line-height: 1.92rem;
  color: #5a5a5a;
  font-family: "Arial";
  display: block;
  align-items: center;
}

.filedGroupCheckboxText .tncDesc {
  padding-bottom: 1rem;
  font-size: 1.16rem;
  line-height: 1.92rem;
  color: #5a5a5a;
  font-family: "Arial";
}

.tokyoDesc {
  margin: 0;
  padding: 0.5rem 1.75rem 0 4.3rem;
  font-size: 0.98rem;
  line-height: 1.42rem;
  color: #727272;
  font-family: "Arial";
  display: block;
}
.checkboxWpr {
  width: 32px;
}
.tnc {
  display: block;
  -webkit-appearance: none;
  border: 1px solid #ccc;
  padding: 5px;
  margin: 0;
  width: 26px;
  height: 26px;
}

/* .tnc:checked {
  display: block;
  -webkit-appearance: none;
  border: 1px solid rgba(0, 75, 153, 1);
  background: url("../image/verification-mark.png") no-repeat center;
  background-size: 70%;
} */

.rightCart .flex1 .listCnt .slPkgUk .slpkgItm .srdes {
  justify-content: start;
  text-align: left;
}
.rightCart .flex1 .listCnt .slPkgUk .slpkgItm .videdetails {
  margin-left: auto;
}

.rightCart .flex2 {
  margin-top: auto;
  justify-self: flex-end;
  display: flex;
  flex-direction: row;
}
.rightCart .flex2 .cartOffers,
.rightCart .flex2 .addContentWpr {
  flex: 1;
  margin: 1rem 0;
  padding: 1rem 2.5rem 0.5rem;
  position: relative;
}

.rightCart .flex2 .cartOffers:first-child::after {
  content: "";
  background: #e3e3e3;
  width: 1px;
  position: absolute;
  right: 0;
  bottom: 1rem;
  top: 1rem;
}

.offerTitle,
.cartPackagesOfferText {
  margin: 0;
  padding: 0;
}
.offerTitle {
  font-size: 1.5rem;
  color: #0f3263;
  position: relative;
}
.rightCart .flex2 .addContentWpr .addContent {
  display: flex;
  justify-content: stretch;
}

.rightCart .flex2 .addContentWpr .addContent > div {
  flex: 1;
}

.offerSubTitle {
  color: #5a5a5a;
  font-size: 1rem;
  font-family: "Arial";
}
.cartPackagesOfferText {
  font-size: 0.88rem;
  color: #5a5a5a;
  font-family: "Arial";
  line-height: 1.2rem;
}
.packageDate {
  font-family: "Arial";
  font-size: 2.66rem;
  color: #0183bd;
  display: inline-block;
  width: 50%;
  margin: 0.9rem 0 0;
  padding: 0;
  line-height: 2.8rem;
  position: relative;
}
.packageDate span {
  font-size: 1.5rem;
  padding-left: 0.5rem;
}
.packageDate:first-child {
  padding-right: 2rem;
}

.packageDate:first-child::after {
  content: "";
  background: #e3e3e3;
  width: 1px;
  position: absolute;
  right: 1rem;
  bottom: 0rem;
  top: 0rem;
}
.rightCart .flex3 {
  justify-self: flex-end;
  margin-bottom: 0;
}
.rightCart .flex3 .button {
  margin: 0;
  width: 50%;
  padding: 1.5rem 4.3rem;
}

.rightCart.shareQuote .flex3 .button {
  margin: 0;
  width: 100%;
  padding: 1.5rem 4.3rem;
}

.rightSaveQuotes {
  width: 50rem;
  padding: 0;
  margin: 0;
  float: right;
  height: 100%;
}

.rightNavigation .close-button {
  background: rgba(0, 75, 153, 1);
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: none;
  position: absolute;
  margin: 0;
  padding: 0;
  right: 70rem;
}

.close-button.sahreQuoteClose {
  right: 45rem;
}

.rightNavigation .add-button {
  background: rgba(0, 75, 153, 1);
  width: 3rem;
  height: 3rem;
  text-align: center;
  border: none;
  margin: 0;
  padding: 0;
  float: right;
}
.rightNavbar {
  padding: 0;
  margin: 0;
}
.rightCart.shareQuote .flex2 .cartOffers {
  padding: 0;
}
.cartOffers .contentList {
  display: block;
  margin: 0;
  padding: 0;
  height: 133px;
  width: 40rem;
  white-space: nowrap;
  overflow: auto;
}
.cartOffers .contentList .browseList {
  display: inline-block;
  margin: 0 1rem 0 0;
  width: 187px;
  height: 113px;
}

.fgtPwdText {
  font-size: 1.08rem;
  color: #5a5a5a;
  line-height: 1.45rem;
  margin: 0 0 3rem 0;
}
